// Styled list
dl.floatList {
	@include pie-clearfix;

	dt, dd {
		display: block;
		vertical-align: top;
		float: left;
	}

	dt {
		padding-right: .5rem;
		width: 40%;
		clear:left;
	}

	dd {
		width: 60%;
	}
}
body.nojs {
	.fadeIn {
		opacity: 1;
		left: 0;
	}
	.fadeInright {
		opacity: 1;
		left: 0;
	}
}
.fixedBtn{
    display:none;
    
    @include breakpoint(giant) {
        position: fixed;
        display:block;
        top:rem(200px);
        z-index: 999;
        right:0;


        li{
           padding: 0.7rem 1rem 0.7rem;

            &.mailLink {
                background-color:$red;
                text-align: center;
                color:$light;
                transition: all 300ms ease-in-out ;
                    
                    .fa {
                    	font-size: 1.8rem;
                    }
                    .mailMain {
                    position: absolute;
                    top:0;
                    opacity: 0;
                    margin-right: 0.7rem;
                    color:$light;
                    visibility: hidden;
                    background: $dark;
                    line-height: 1.5em;
                   padding: 2rem 3rem 1rem;
                   text-align: left;
                	// transition: all 0.3s;

										
										a {
											color:$light;
											text-decoration: underline;
											&:hover {
												text-decoration: none;
											}
										}

										.fa {
                    	font-size: rem(16px);
                    }
                        
                    }
                    &:hover{
                    	background:rgba($red, 0.8);
                    	.mailMain{
                    		 opacity: 1;
                        visibility: visible;
                        top: 0;
                        right: 3em;
                        // transition: all 300ms ease-in-out ;
                    	}
                    }


                     
                       
               
            }
        }
    }
}
#WidgetBannerJq8zs9Yr {
    position: fixed;
    // left: 1rem;
    z-index: 9999;
    // bottom: 1rem;
    bottom:0;
    left: 0;
}
// SOME HELPFUL CLASSES
//////////////////////////////
body.index {
	.videoContainer {
	// height:13rem;
	height: rem(280px);
	overflow: hidden;
	position: relative;
	margin-bottom: 7rem;
	display: none;
	@include breakpoint(small) {
			display: block;
	 }
	@include breakpoint(medium) {
		height:27rem;
		// height: rem(325px);
	 }
	 @include breakpoint(large) {
	 	height:32rem;
	  }
	 
	 @include breakpoint(giant) {
		height: rem(684px);
		// height: rem(810px);
	 }

	.claim {
	    display: none;
	    position: absolute;
	    z-index: 99999;
	    @include breakpoint(medium) {
	   		 display: block;
	    	width: 75%;
	    	margin: 4rem 2rem;
	     }
	     @include breakpoint(large) {
	   		 display: block;
	    	width: 82%;
	    	margin: 7rem 2rem;
	     }
	 			@include breakpoint(giant) {
	 			margin:10rem 2rem;
	 				 			 }
	 			
	     @include breakpoint(huge) {
	     		width: 70%;
	      }
	     
	    
	    p {
	    	text-transform: uppercase;
	    	font-size:3.3rem;
	    	font-weight: bold;
	    	color:$light;
	    	line-height: rem(75px);
	    	@include breakpoint(large) {
	    		font-size:4.3rem;
	    	 }
	    	
	    }
	   .normal {
				font-size: rem(20px);
				font-weight: 400;
				text-transform: none;
				line-height: rem(26px);
	    }
	    span {
	    	font-size: 2.3rem;
	    	@include breakpoint(large) {
	    	font-size: 3.356rem;
	    	 }
	    }
	    .light {
	    	font-weight: 400;
	    	font-size:3.3rem;
	    	@include breakpoint(large) {
	    		font-size:4.3rem;
	    	 }
	    }
		}
	.video-wrap {
		margin-top: 0;
		video {
			height: 100%;
			width: 100%;
			pointer-events: none;
		}
		
	}
}
}
.videoContainer {
	// height:12rem;
	overflow: hidden;
	position: relative;
	margin-bottom: 5rem;
	display: none;
	height:15rem;

// body:not(.index) &{
// 	@media only screen and (max-height: 816px) {
// 			height:12rem;
//     }
// }

	@include breakpoint(small) {
		display: block;
	 }
	@include breakpoint(medium) {
		// height:10rem;
		height:18rem;

	 }
	 @include breakpoint(large) {
	 	// height:12rem;
	 	height:20rem;

	  }
	 
	 @include breakpoint(giant) {
		height: 12rem;
	 }
	.claim {
	    display: none;
	   
		}
	.video-wrap {

		@include breakpoint(giant) {
				margin-top: -20rem;
		 }
		
		video {
			height: 100%;
			width: 100%;
		}
		
	}
}
.cta {
	background: $lightgrey;
	padding: 1.5rem 1rem 0.5rem;
	font-size: rem(22px);

	a {
		text-decoration: none;
	}

	.contacs {
		display: flex;
    	flex-flow: row wrap;
	}

	@include breakpoint(medium) {
			padding: 1.5rem 1rem 0rem;
	 }
	
	.fa {
		color:$red;
		line-height: 1.6rem;
		margin-right: 0.7rem;
	}
	.phone {
		@include breakpoint(tiny) {
				margin-right: 2rem;
		 }
		 @include breakpoint(small) {
				margin-right: 4rem;
		 }

		 &.distPhone{
		 	@include breakpoint(small){
		 		margin-right: 2rem;
		 	}
		 }
	}
	.fa-phone {
		font-size: rem(24px);
	}
	.fa-envelope {
		font-size:rem(20px);
	}
}
.highlight {
	color: $secondary;
}

.btn {
	background: $red;
	border: none;
	color: $light;
	cursor: pointer;
	display: inline-block;
	font-size: rem(20px);
	line-height: 1rem;
	// padding:1.1rem 0 1.2rem 1.2rem;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	text-decoration: none;
	transition: 300ms all ease-in-out;
	font-family: $main-font;
	min-height: 1rem !important;
 	width: fit-content;
	position: relative;
	align-items: center;
	display: flex;
	padding-left:1rem;

	@include breakpoint(huge){
		font-size: rem(27px);
	}

	&:hover, &:active, &:focus {
		background: $dark;
		color: $light;
	}

	*[class^="icon"] {
		margin-left: .5rem;
		vertical-align: middle;
	}
	&:after {
		background: $light;
		font-family: $icon-font;
		content:'\f054';
		padding: .9rem .9rem 1.1rem .9rem;
		font-size: rem(24px);
		color: $red;
		margin-left: 1rem;

		height: 100%;
    	display: inline-block;
    	position: relative;
	}
}

.notification {
	padding: 1em;
	background: $alert;
	color: $light;
	font-size: 1.2em;
}
.cta {
	background: $lightgrey;
	padding: 1.5rem 1rem 1.3rem 1rem;
	font-size: rem(18px);

	@include breakpoint(small){
		font-size: rem(20px);
	}

	span {
		text-transform: uppercase;
		font-weight: bold;
		margin-right: 2.5rem;
	}

	@include breakpoint(medium) {
			padding: 1.5rem 1rem 0rem;
	 }
	
	.fa {
		color:$red;
		line-height: 1.6rem;
		margin-right: 0.7rem;
	}
	.phone {
		@include breakpoint(tiny) {
				margin-right: 2rem;
		 }
		 @include breakpoint(small) {
				margin-right: 4rem;
		 }
	}
	.fa-phone {
		font-size: rem(20px);
	}
}
.widget {
	background: $lightgrey;
	color:$blue;
	font-weight: bold;
	// padding: 2rem 1rem 1rem;
	font-size: rem(24px);
	line-height: 1.7rem;
	margin: 4rem 0 0rem;
	text-transform: uppercase;
	text-align: center;
	width: 100%;
	padding: 2.5rem 1rem 2.5rem 1rem;


	@include breakpoint(medium){
		font-size: rem(29px);
	}

		a {
			color:$red;
			display: inline;
			&:hover {
				color:$dark;
			}
		}
			@include breakpoint(giant) {
				padding:2rem 3rem 1.7rem;
				text-align: right;
			}
		p {
			margin: 2rem 0rem 0 0;
			float: right;

			@include breakpoint(large){
			margin: 3.3rem 0rem 0 0;

			}

			@include breakpoint(huge){
				margin: 3.3rem 0rem;

			}
		}
	.siegel {
		margin: 0 auto;
		@include breakpoint(giant) {
			float: left;
		}
	}
}
.block {
	display: block;
}

.tel {
	text-decoration: none !important;
}
.contactForm {
	background: $lightgrey;
	// padding: rem(105px) 0;
	padding: rem(90px) 0;
	margin: rem(120px) 0;

	@include breakpoint(medium){
		padding: rem(105px) 0;
	}

	a { 
			text-decoration: none !important;
		}
	.h1 {
		line-height: 4.5rem;
		margin-bottom: 4rem;
		font-weight: bold;
		span {
			font-size:rem(22px);
			line-height: 1.5rem;
		}
	}
	.adress {
		margin-bottom: rem(60px);
		.fa {
			font-size:rem(45px);
			color:$red;
			padding-right: 1.5rem;
			border-right:1px solid $dark;
			@include breakpoint(small) {
				padding-right: 2.8rem;
			 }
			
		}
		span {
			margin-left: 1rem;
			line-height: 2.3rem;
			vertical-align: top;
		}
	}
	.phone {
		margin-bottom: rem(60px);
		font-size:rem(20px);


		span {
			margin-left: 1rem;
			line-height: 2.3rem;
			vertical-align: top;
		}
		.fa {
			font-size:rem(40px);
			color:$red;
			padding-right: 1rem;
			border-right:1px solid $dark;
			@include breakpoint(small) {
				padding-right: 2.5rem;
			 }
			
		}
	}
	.mail {
		font-size:rem(20px);
		margin-bottom: 3rem;
		span {
			margin-left: 1rem;
			line-height: 2rem;
			vertical-align: top;
		}
		.fa {
			font-size:rem(32px);
			color:$red;
			padding-right: 0.9rem;
			border-right:1px solid $dark;
			@include breakpoint(small) {
				padding-right: 2.5rem;
			 }
			
		}
	}
}
// PAGE WRAP
//////////////////////////////

.page-wrap {
	transform: none;
}

// HEADER
//////////////////////////////

.header {
	background: $light;
	position: relative;
	width: 100%;

	.branding {
		display: block;
		text-align: center;
		margin:1rem $base-gap;

		@include breakpoint(medium){
			margin:1rem 0;
		}

		img {
			width: 16rem;
		}
	}
}

body.index {
	.branding {
		img {
			width: auto !important;
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	ul {
		@extend .styled-list;
	}

	.google-maps {
		@extend .video-container;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}
}
.transit {
	color:$blue;
	font-size:rem(20px);
	text-transform: uppercase;
	font-weight: bold;
	margin-bottom: rem(35px);
	@include breakpoint(medium){
		margin-bottom: rem(25px);
	}
}
.transitImg {
	width: auto !important;
	margin-top: -2rem;
}
// FOOTER
//////////////////////////////

.footer {
	display: block;
}


	.teaser {
		color:$light;
		padding: 0 $base-gap;
		@include breakpoint(giant){
			padding: 0;
		}

		.contentUl {
				li {
					&:before {
						color:$light;
					}
				}
			}

		.col {
			padding: 0;
		}
		&.services {
			margin-bottom: 2rem;
			@include breakpoint(medium) {
				margin-top: 2rem;
			 }
			 @include breakpoint(large) {
			 		margin-bottom: 0;
			  }
			 
		}
		&.support {
			margin-bottom: 2rem;
			@include breakpoint(large) {
			 		margin-bottom: 0;
			  }
		}
		.headline {
			font-size:rem(30px);
			text-transform: uppercase;
			font-weight: bold;
			line-height: 2rem;
			color:$light;
		}
		.right {
			float: right;
			img {
				@include breakpoint(medium) {
						padding: 0.8rem;
				 }
				@include breakpoint(large) {
						padding: 7.5rem 0;
				 }
				 @include breakpoint(giant) {
						padding: 3.3rem 0;
				 }
			}
		}
		img {
			padding: 3rem 0;
			@include breakpoint(medium) {
				padding: 3.8rem 0;
			 }
			 @include breakpoint(large) {
			 	padding: 6.5rem 0;
			  }
			 
			 @include breakpoint(giant) {
			 		padding: 3.2rem 0;
			  }
			 
			
		}
		.bg {
		background: $blue;
		padding: 2.5rem 1.5rem;
		margin-bottom: 2rem;
			@include breakpoint(small) {
						padding: 2.5rem 1.5rem 2.5rem 20rem;
						margin-bottom: 4rem;
				 }
			@include breakpoint(medium) {
					margin:3rem 0;
					padding: 2.5rem 1.5rem 2.5rem 28rem;
					margin-bottom:  2rem;
			 }
			@include breakpoint(large) {
					padding: 3.5rem 3.5rem 3.5rem 30rem;
					margin: 5.6rem 0;
			 }
			
			}

		.bg2 {
			background: $blue;
			padding: 2.5rem 1.5rem;
			margin-bottom: 2rem;
				@include breakpoint(small) {
						padding: 2.5rem 20rem 2.5rem 1.5rem;
				 }
				@include breakpoint(medium) {
					margin-bottom:2rem;
					padding: 3.2rem 28rem 3.2rem 1.5rem;
			 }
			 @include breakpoint(large) {
			 		padding: 4rem 30.5rem 4rem 4rem;
			 		margin: 5.8rem 0;
			  }
			 
			}
	}

.contentUl {
	li {
		padding: 0.1rem 0 0.1rem 1.5rem;
    text-indent: -1.5rem;
		&:before {
			content:'\f00c';
			font-family: $icon-font;
			font-size:rem(16px);
			color:$red;
		}
	}
}
.fadeIn {
	@include breakpoint(huge) {
				position: relative;
				opacity: 0;
				left: 100px;
	 }			
}
.fadeInright {
	@include breakpoint(huge) {
			position: relative;
			opacity: 0;
			left: -100px;	
	 }
}
