/*=NAVIGATION MAIN */
.navi-main {
	text-align: center;
	background: $blue;
	
	width: 100%;
	
	@include breakpoint(medium) {
		display: flex;
		justify-content: space-between;
	 }
	

	li {
		font-size: 0.9rem;
		

		@include breakpoint(medium) {
			border-bottom: 0;
			flex:1 1 auto;
			width: auto;
		 }

		 
	
		 
		

		&:hover {
			
			span {
				
			background: rgba(255,255,255,0.2);
			}
		}

		&.active {
			span {
			background: $red;
			}
		}

		a {
			border-bottom: 1px solid rgba(255,255,255,0.2);
			color: $light;
			display: block;
			padding: 1rem 0;
			text-transform: uppercase;
			text-decoration: none;
			font-size: 0.8rem;
			@include breakpoint(giant) {
				font-size:rem(20px);
			 }
			
			&:hover {
				background: rgba(255,255,255,0.2);
			}
			&.active {
				background: $red;
			}
		}
		span {
			border-bottom: 1px solid rgba(255,255,255,0.2);
			color: $light;
			display: block;
			padding: 1rem 0;
			text-decoration: none;
			font-size: 0.8rem;
			@include breakpoint(giant) {
				font-size:rem(20px);
			 }
			
		}
	}

	
	li.hasSub { 
		position: relative; 
		
		
	}
	
	li.hasSub a {

	padding-right: 1rem;	
	}

	li.hasSub a.active {
		position: relative;
		color: $light;
		background: rgba(255,255,255,0.4);
	}



	
	li.hasSub:hover ul {
		
		display: block;
	}

	.sub {
		display: block;
		

		@include breakpoint(medium) {
			display: none;
			position: absolute;
			top: 3.4rem;
			width: 170%;
		 }
		
		@include breakpoint(giant) {
			width: 100%;
		 }
		

		li {
			display: block;
			text-transform: none;
			padding: 0;
			font-size: 0.9rem;	
			margin-bottom: 0.1rem;

			
			@include breakpoint(medium) {
			 	text-align: left;
			 	background: $red;
			 }
			

			a {
				display:block;
				border-bottom: 1px solid rgba(0,0,0,0.1);
				color:$light !important;

				@include breakpoint(medium) {
					 padding: 1rem;
				 }
				
			}

			&:hover {
				
				color: $lightgrey; 

				a {
				color:$lightgrey !important;

				@include breakpoint(medium) {
						color: $light !important; 
						
				 }
				
				}
			}

			&.active {
				color: #ccc; 
				
				a {
					background: rgba(255,255,255,0.3);
					
				}


			}
		}
	}

}



.navi-add {
	text-align: center;
	background: $blue;
	margin-top: 3rem;
	padding-bottom:rem(70px);
	@include breakpoint(large){
		padding-bottom: 0;
	}

	li {

	display: inline-block;
	

		@include breakpoint(medium) {
				border-bottom: none;
				display: inline-block;
		 }
		
		
		

		&:hover {


			
			a {
				color:$medium;

			}
		}

		&.active {
			a {
				color: $medium;
				background: rgba(0,0,0,0.1);
			}
		}

		a {
			color: $light;
			text-decoration: none;
			padding: 1.2rem 1rem;
			display: block;
			font-size: 0.9rem;

		}
	}


}
// Off-canvas navigation
.page-navi {
	background: $blue;
	min-height: 100%;
	transform: translateX(-100%);
	left: 0;
	position: absolute;
	top: 0;
	width: 80%;
	z-index: 100;
	transition: all 300ms ease-in-out ;

	@include breakpoint(medium) {
		background: $blue;
		transform: translateX(0);
		transition: none;
		min-height: inherit;
		position: relative;
		width: 100%;
	}
}

.toggle-navi, .close-navi {
	color: $blue;
	font-size: rem(32px);
	text-decoration: none;
	margin-left: 1rem;

	&:before {
		@extend .fa-default;
		@extend .fa-bars;
		transition: content 300ms;
		line-height: 2.6em;
	}

	&:hover {
		&:before {
			color: $dark;
		}
	}

	@include breakpoint(medium) {
		display: none;
	}
}

.close-navi {
	display: none;
}

.navi-buttons {
	float: left;
}

#navi-toggled:target {
	.page-navi {
		transform: none;
	}

	.page-wrap {
		transform: translateX(80%);
		position: fixed;
	}

	.close-navi {
		display: block;

		&:before {
			@extend .fa-close;
		}
	}

	.toggle-navi {
		display: none;
	}

	@include breakpoint(medium) {
		.close-navi {
			display: none;
		}

		.page-wrap {
			transform: none;
			position: relative;
		}
	}
}
